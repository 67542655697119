.MyStudyClan__brand {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.MyStudyClan__brand div {
    flex: 1;
    max-width: 150px;
    min-width: 120px;

    display: flex;
    justify-content: center;
    align-items: center;
}