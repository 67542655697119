
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #89becb 1.84%, #67ccf4 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #67dffa -13.86%, #1a7bcb 99.55%);
  
  --color-bg: #1d2c37;
  --color-footer : #062d68;
  --color-blog: #38aad0;
  --color-text: #f6f7f8;
  --color-subtext: #ecedf4;
}